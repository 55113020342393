import { TFunction } from 'i18next';
import { useParams } from 'next/navigation';

const Section6 = ({ t }: { t: TFunction<'translation', undefined> }) => {
  const params = useParams();

  return (
    <section className="sec-6 tw-w-full tw-flex tw-justify-center tw-items-center tw-bg-gray-100 ">
      <div className="xl:tw-w-[1358px] tw-w-full xl:tw-my-10 tw-my-5 xl:tw-h-[672px] tw-h-full xl:tw-px-[220px] tw-px-4 xl:tw-pt-[52px] tw-pt-8 tw-pb-8 tw-flex-col tw-justify-center tw-items-center tw-gap-2.5 tw-inline-flex">
        <header className="tw-self-stretch xl:tw-h-[76px] tw-h-full tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-flex">
          <h2 className="tw-self-stretch tw-text-center tw-text-gray-700 tw-text-4xl tw-font-bold tw-font-['Figtree'] tw-leading-10">
            {t('Home/Section6/key1').toString()}
          </h2>
          <h3 className="tw-self-stretch tw-text-center tw-text-gray-700 tw-text-xl tw-font-semibold tw-font-['Figtree'] tw-leading-7">
            {t('Home/Section6/key2').toString()}
          </h3>
        </header>

        <article className="tw-self-stretch xl:tw-h-[558px] tw-h-full tw-flex tw-justify-center tw-items-center">
          <iframe
            className="xl:tw-h-[558px] tw-h-[300px] tw-w-full"
            src="https://www.youtube.com/embed/d9waaV2LFOU?si=jbKRf9Ue90wJNDZI&rel=0&controls=1&modestbranding=1&showinfo=0&fs=1&iv_load_policy=3"
            title="Turn interactions into opportunities with VideoBot/Chatbots"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            aria-label="Video player"
            loading="lazy"
          ></iframe>
        </article>
      </div>
    </section>
  );
};

export default Section6;
